import React, { useEffect, useState } from "react";
import KpiPieChart from "./KpiPieChart";
import "../../Styles/home.css";
import "../../Styles/alluser.css";
import { useDispatch, useSelector } from "react-redux";
import { userKpiInterestAction } from "../../redux/reducers/user/userKpiInterest";
import { authAxios } from "../../config/authAxios";

function UserInterestPie() {
  const [chartData, setChartData] = useState([]);

  const dispatch = useDispatch();
  const { message, status, loading, data } = useSelector(
    (state) => state.kpiUserInterest
  );


  useEffect(() => {
    dispatch(userKpiInterestAction());
  }, [dispatch]);

  useEffect(() => {
    if (data && Array.isArray(data)) {
      const formattedData = data.map((item) => ({
        name: item._id,
        value: item.count,
      }));
      setChartData(formattedData);
    }
  }, [data]);

  const handleDownload = async () => {
    try {
      const response = await authAxios.get("/userListBasedOnInterest", {
        responseType: "blob", 
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "user_interests.csv"); 
      document.body.appendChild(link);
      link.click();
      link.remove(); 
    } catch (error) {
      console.error("Error downloading the CSV:", error);
    }
  };


  return (
    <div className="live-chart-div margin-left w-100">
      <div className="line-chart-section">
        <div className="kpiInterest">
      <button  className="filter-btn bg-btn-col"
      onClick={handleDownload}>Download</button >
        <h3>User Interest</h3>
        </div>

        {chartData.length?<div>
          <KpiPieChart
            data={chartData}
            xKey="name"
            yKey="value"
            yLabel="Count"
          />
        </div>:<div style={{color:"green",display:'flex', justifyContent:"center" }}>No data available</div>}
      </div>
    </div>
  );
}

export default UserInterestPie;